import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { encodeToFormData, isEmail } from "../../utils"
import * as styles from "./PPCForm.module.scss"
import PhoneCustomInput from "../common/phoneInput"
import "./PPCForm.scss"
import { navigate } from "gatsby"

const FaqForm = ({
  pageName = "unknown",
  reactForm,
  iosform,
  angularform,
  productForm,
  mobileApp,
  hireWeb,
  android,
  hirePython,
}) => {
  const [status, setStatus] = useState("init")
  const [mapBoxneeded, setBoxmapBoxneeded] = useState(false)
  const [phoneFormat, setPhoneFormat] = useState(null)
  const [isValidPhoneFormat, setIsValidPhoneFormat] = useState(true)

  const initValues = {
    fullName: "",
    email: "",
    query: "",
    page: pageName,
    phoneContact: "",
  }
  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { type, name } = e?.target
    if (type === "checkbox") values[name] = e?.target?.checked
    else if (type === "file") values[name] = e?.target?.files[0]
    else values[name] = e?.target?.value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "fullName":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          break
        case "email":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"
          break
        case "query":
          if (!value || value?.trim() === "")
            newErrors[key] = "This field is required!"
          break
        case "phoneContact":
          // if (value?.indexOf("0") === 0)
          //   newErrors[key] = "Not Start number with zero"

          break
        default:
          break
      }
    }
    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate(values)
    if (Object.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors)
      return
    }
    if (!isValidPhoneFormat) {
      return
    }
    setStatus("submitting")
    let local_data = {
      ...values,
      page: "PPC Page",
      phone:
        values?.countryName &&
        values?.phoneNumber &&
        `(${values?.countryName?.toUpperCase()}) +${values?.countryCode} ${
          values?.phoneNumber
        }`,
    }
    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9035`
    const formData = encodeToFormData(local_data)

    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })

      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        return navigate("/thank-you/", {
          state: {
            pathname: window.location.pathname,
            fullName: local_data?.fullName,
            email: local_data?.email,
          },
        })
        // setValues(initValues)
        // setStatus("success")
        // setTimeout(() => {
        //   setStatus("init")
        // }, 2000)
      } else {
        setStatus("failed")
        throw new Error("Server error!")
      }
    } catch (err) {
      setStatus("failed")
    }
  }

  return (
    <React.Fragment>
      <div className={`${styles.formInner} hireForm`}>
        <h3>
          {hirePython
            ? "70+ Python Developers Available For Hire!"
            : hireWeb
            ? "Book Your Web Developer Interview & Start Rolling."
            : mobileApp
            ? "Get Top Mobile App Developers In One Tap!"
            : reactForm
            ? "Hire Dedicated ReactJS Developers For Secure And Resilient Web Solutions!"
            : iosform
            ? "Hire Dedicated iOS Developers From Us To Build Your Dream Apps."
            : angularform
            ? "Hire The Best Angular Developers In Just 2 Days"
            : productForm
            ? "Looking For A Product Manager?"
            : android
            ? "Hire the Top 2% Of Android Developers in 48 hours"
            : "Schedule Your Developer Interview & Get Started Within 24-48 Hours."}
        </h3>
        <p>
          {reactForm ||
          iosform ||
          angularform ||
          mobileApp ||
          productForm ||
          hireWeb ||
          android
            ? ""
            : "Our leadership will reply personally. You will get the response within 2 hours."}
        </p>
        {hireWeb || productForm || android ? "" : <hr className={styles.hr} />}
        <Form name="Contact Sales Form" method="POST" onSubmit={handleSubmit}>
          <Form.Group controlId="fullName">
            <Form.Control
              type="text"
              placeholder="Name*"
              name="fullName"
              value={values?.fullName}
              onChange={handleChange}
              isInvalid={errors?.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.fullName}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email*"
                  name="email"
                  value={values?.email}
                  onChange={handleChange}
                  isInvalid={errors?.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="phoneNumber" className="mb-4">
                {/* <Form.Label className="font-weight-normal px-1">
                  Phone Number
                </Form.Label> */}

                <PhoneCustomInput
                  value={values?.phoneContact}
                  mapBoxneeded={mapBoxneeded}
                  onFocus={() => setBoxmapBoxneeded(true)}
                  onChange={(value, data, e, formattedValue) => {
                    setPhoneFormat(formattedValue)
                    const { dialCode, countryCode } = data
                    setValues({
                      ...values,
                      phoneContact: value,
                      phoneNumber: value?.slice(dialCode?.length),
                      countryCode: dialCode,
                      countryName: countryCode,
                    })
                  }}
                  isValid={(value, country) => {
                    const { format, dialCode } = country
                    if (
                      format?.length === phoneFormat?.length &&
                      (value?.startsWith(dialCode) ||
                        dialCode?.startsWith(value))
                    ) {
                      setIsValidPhoneFormat(true)
                      return true
                    } else if (value) {
                      setIsValidPhoneFormat(false)
                      return "Invalid Format"
                    } else if (value.match(/12345/)) {
                      setIsValidPhoneFormat(false)
                      return "Invalid Format"
                    } else if (value.match(/1234/)) {
                      setIsValidPhoneFormat(false)
                      return false
                    } else {
                      setIsValidPhoneFormat(true)
                      return true
                    }
                  }}
                />
                {errors?.phoneContact && (
                  <small
                    type="invalid"
                    className="text-danger position-absolute"
                  >
                    {errors?.phoneContact}
                  </small>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="query" className={styles.fieldColor}>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Please Fill your Project Requirements*"
              name="query"
              value={values?.query}
              onChange={handleChange}
              isInvalid={errors?.query}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.query}
            </Form.Control.Feedback>
          </Form.Group>

          <div className=" submit-button-cta text-left">
            <Button
              variant="primary"
              type="submit"
              aria-label="Send"
              disabled={status !== "submitting" ? false : true}
              className={styles.query}
              id="sale_section_contact_us_form_gt"
            >
              {status === "submitting" ? (
                <React.Fragment>
                  <Spinner
                    variant="light"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span> Sending</span>
                </React.Fragment>
              ) : (
                `${
                  hirePython
                    ? "Hire Python Developers Now"
                    : hireWeb
                    ? "Hire Web Developers Now"
                    : mobileApp
                    ? "Hire Mobile App Developers Now"
                    : angularform
                    ? "Hire Angular Developers Now"
                    : productForm
                    ? "Schedule A Call"
                    : android
                    ? "Hire Android Developers"
                    : "Send Your Inquiry"
                }`
              )}
            </Button>
          </div>
          <div>
            {status === "success" ? (
              <p
                className=" m-0 mt-2 w-100 text-left "
                style={{ fontSize: "12px,", color: "#0FC30F" }}
              >
                Submit Successfully
              </p>
            ) : status === "failed" ? (
              <p
                className="text-danger m-0 mt-2 w-100 text-left "
                style={{ fontSize: "12px" }}
              >
                Somthing Went Wrong
              </p>
            ) : (
              <p className="m-0 mt-2 w-100 text-left"></p>
            )}
          </div>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default FaqForm
